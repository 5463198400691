import { ethers } from 'ethers';
import { getInstance } from '@snapshot-labs/lock/plugins/vue3';
import { getErc20Contract, getRouterContract, getWethContract } from '@/helpers/contract.helper';
import { getRouterAddress } from '@/helpers/address.helper';
import { ISwapForm } from './models/swap-form.interface';
import { isSwapETHForWETH, isSwapWETHForETH } from './swap-form-methods';
import { getSwapType, swapETHForWETH, swapMethod, swapWETHForETH } from './swap-methods';

export function getInstanceOfRouterContract() {
  console.log('[SWAP] Signer for smart contract : ', getInstance().web3.getSigner());

  return getRouterContract(getRouterAddress(), getInstance().web3.getSigner());
}

export async function checkERC20Allowance(address: string, account: string): Promise<any> {
  console.log('checkERC20Allowance', address);
  const tokenContract = getErc20Contract(address, getInstance()?.web3?.getSigner());
  console.log('tokenContract', tokenContract);
  return await tokenContract.allowance(account, getRouterAddress());
}

// Swaps
export function createSwapTransaction(
  swapForm: ISwapForm,
  account: string,
): Promise<ethers.providers.TransactionResponse> {
  const routerContract = getInstanceOfRouterContract();

  if (isSwapETHForWETH(swapForm)) {
    if (swapForm.output.token) {
      const wethContract = getWethContract(
        swapForm.output.token?.address,
        getInstance().web3.getSigner(),
      );
      return swapETHForWETH(swapForm, wethContract);
    }
  }

  if (isSwapWETHForETH(swapForm)) {
    if (swapForm.input.token) {
      const wethContract = getWethContract(
        swapForm.input.token?.address,
        getInstance().web3.getSigner(),
      );
      return swapWETHForETH(swapForm, wethContract);
    }
  }

  const swapType = getSwapType(swapForm);
  return swapMethod[swapType](swapForm, account, routerContract);
}
